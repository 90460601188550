
import { defineComponent, onUnmounted } from 'vue'
import { useRoute } from 'vue-router'
import { useCarWash } from '@/composables/useCarWash'
import localforage from 'localforage'
export default defineComponent({
    setup() {
        const { fetchCarWashStock, fetchCarWashList, fetchCarWash } = useCarWash()
        const route = useRoute()

        if (route.name !== 'CarWashList' && route.name !== 'AddCommentManager') {
            fetchCarWash(+route.params.id)
            fetchCarWashList()
            fetchCarWashStock()
        }

        onUnmounted(() => {
            localforage.removeItem('routeDetailsId')
        })

        return {}
    },
})
